import React from 'react';
import ReactDOM from 'react-dom';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';

class ChipView extends React.PureComponent {
    render() {
        const isSourceArea = window.location.pathname.includes('/area');

        const chipView = (
            <div className={styles.content_list}>
                <Chip id="260197" type="struct" title="手凤和凤首置灰配置" groupName="手凤和凤首置灰配置">
                    <div className={styles.chip_item} />
                </Chip>
                <Chip id="230109" type="struct" title="低版本信息配置" groupName="低版本信息配置">
                    <div className={styles.chip_item} />
                </Chip>
                <Chip id="170184" type="struct" title="顶部副导航地方站定向" groupName="顶部副导航地方站定向">
                    <div className={styles.chip_item} />
                </Chip>
                <div className={styles.top_40}>
                    <Chip id="255850" type="recommend" title="焦点图资讯" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195903" type="recommend" title="焦点图娱乐" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255929" type="recommend" title="焦点图体育" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225816" type="recommend" title="焦点图汽车" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225817" type="recommend" title="焦点图时尚" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255930" type="recommend" title="焦点图军事" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195904" type="recommend" title="焦点图房产" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="288475" type="recommend" title="焦点图视频" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="260795" type="struct" title="焦点图电商第7位和第10位" groupName="焦点图">
                        <div className={styles.chip_item} />
                    </Chip>
                </div>
                <div className={styles.top_40}>
                    <Chip id="260686" type="struct" title="凰家尚品各频道数据维护1" groupName="凰家尚品">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="260687" type="struct" title="凰家尚品各频道数据维护2" groupName="凰家尚品">
                        <div className={styles.chip_item} />
                    </Chip>
                </div>
                {
                    isSourceArea ? (<>
                    <div className={styles.top_40}>
                        <Chip id="255891" type="recommend" title="湖北要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225788" type="recommend" title="山东要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225789" type="recommend" title="青岛要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195881" type="recommend" title="湖南要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195882" type="recommend" title="陕西要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225790" type="recommend" title="江西频道列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195883" type="recommend" title="广东要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195884" type="recommend" title="海南要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195885" type="recommend" title="安徽要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195886" type="recommend" title="河北要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225791" type="recommend" title="浙江要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255894" type="recommend" title="重庆要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195887" type="recommend" title="黑龙江要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195888" type="recommend" title="吉林要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195889" type="recommend" title="甘肃要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255892" type="recommend" title="河南要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255894" type="recommend" title="四川要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195890" type="recommend" title="宁波要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285123" type="recommend" title="江苏要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285551" type="recommend" title="福建要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="287670" type="recommend" title="山西要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                    {/* <Chip id="287539" type="recommend" title="深圳要闻列表" groupName="要闻区地方站">
                        <div className={styles.chip_item} />
                    </Chip> */}
                    </div>
                    <div className={styles.top_40}>
                        <Chip id="255905" type="recommend" title="湖北要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195897" type="recommend" title="山东要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255906" type="recommend" title="青岛要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255907" type="recommend" title="湖南要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225794" type="recommend" title="陕西要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255910" type="recommend" title="江西要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225795" type="recommend" title="广东要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225796" type="recommend" title="海南要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255908" type="recommend" title="安徽要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255909" type="recommend" title="河北要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225797" type="recommend" title="浙江要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225798" type="recommend" title="重庆要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255911" type="recommend" title="黑龙江要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255912" type="recommend" title="吉林要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255913" type="recommend" title="甘肃要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255914" type="recommend" title="河南要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225798" type="recommend" title="四川要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255916" type="recommend" title="宁波要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285122" type="recommend" title="江苏要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285550" type="recommend" title="福建要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="287671" type="recommend" title="山西要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        {/* <Chip id="287554" type="recommend" title="深圳要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip> */}
                    </div>
                </>) : (
                    <>
                    <div className={styles.top_40}>
                        <Chip id="225768" type="recommend" title="湖北要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225769" type="recommend" title="山东要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255882" type="recommend" title="青岛要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255883" type="recommend" title="湖南要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255884" type="recommend" title="陕西要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255885" type="recommend" title="江西频道列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195880" type="recommend" title="广东要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225770" type="recommend" title="海南要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255886" type="recommend" title="安徽要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255887" type="recommend" title="河北要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255888" type="recommend" title="浙江要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225771" type="recommend" title="重庆要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255889" type="recommend" title="黑龙江要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225772" type="recommend" title="吉林要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225773" type="recommend" title="甘肃要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225774" type="recommend" title="河南要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255894" type="recommend" title="四川要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225775" type="recommend" title="宁波要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285089" type="recommend" title="江苏要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285551" type="recommend" title="福建要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="287673" type="recommend" title="山西要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        {/* <Chip id="287539" type="recommend" title="深圳要闻列表" groupName="要闻区地方站">
                            <div className={styles.chip_item} />
                        </Chip> */}
                    </div>
                    <div className={styles.top_40}>
                        <Chip id="255895" type="recommend" title="湖北要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255896" type="recommend" title="山东要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255897" type="recommend" title="青岛要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255898" type="recommend" title="湖南要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225792" type="recommend" title="陕西要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255899" type="recommend" title="江西要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225793" type="recommend" title="广东要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255900" type="recommend" title="海南要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195891" type="recommend" title="安徽要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195892" type="recommend" title="河北要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195893" type="recommend" title="浙江要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195894" type="recommend" title="重庆要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255901" type="recommend" title="黑龙江要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255902" type="recommend" title="吉林要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195895" type="recommend" title="甘肃要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255903" type="recommend" title="河南要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225798" type="recommend" title="四川要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195896" type="recommend" title="宁波要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285090" type="recommend" title="江苏要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285550" type="recommend" title="福建要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="287674" type="recommend" title="山西要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip>
                        {/* <Chip id="287554" type="recommend" title="深圳要闻最后一条" groupName="要闻区地方站最后一条">
                            <div className={styles.chip_item} />
                        </Chip> */}
                    </div>
                </>
                )
                }
                
                <div className={styles.top_40}>
                    <Chip id="195869" type="recommend" title="房产列表2" groupName="房产">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255917" type="recommend" title="房产列表4" groupName="房产">
                        <div className={styles.chip_item} />
                    </Chip>
                </div>
                <div className={styles.top_40}>
                    <Chip id="255928" type="recommend" title="房产广州头条" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255927" type="recommend" title="房产列表二广州" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195902" type="recommend" title="房产列表二青岛" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225815" type="recommend" title="房产列表二大连" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195901" type="recommend" title="房产列表二南京" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255926" type="recommend" title="房产列表二杭州" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225814" type="recommend" title="房产列表二上海" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255925" type="recommend" title="房产列表二西安" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225813" type="recommend" title="房产列表二长沙" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255924" type="recommend" title="房产列表二济南" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225812" type="recommend" title="房产列表二成都" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195900" type="recommend" title="房产列表二重庆" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195899" type="recommend" title="房产列表二深圳" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255923" type="recommend" title="房产列表二天津" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225811" type="recommend" title="房产列表二烟台" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195898" type="recommend" title="房产列表二武汉" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                </div>
                <div className={styles.top_40}>
                    <Chip id="225810" type="recommend" title="房产列表四广州" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255922" type="recommend" title="房产列表四青岛" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225809" type="recommend" title="房产列表四大连" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225808" type="recommend" title="房产列表四南京" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225807" type="recommend" title="房产列表四杭州" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225806" type="recommend" title="房产列表四上海" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225805" type="recommend" title="房产列表四西安" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225804" type="recommend" title="房产列表四长沙" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255921" type="recommend" title="房产列表四济南" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255920" type="recommend" title="房产列表四成都" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225803" type="recommend" title="房产列表四重庆" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225802" type="recommend" title="房产列表四深圳" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225801" type="recommend" title="房产列表四天津" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225800" type="recommend" title="房产列表四烟台" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="255919" type="recommend" title="房产列表四武汉" groupName="房产地方站">
                        <div className={styles.chip_item} />
                    </Chip>
                </div>
                <div className={styles.top_40}>
                    <Chip id="200147" type="struct" title="北京河北地区小说标题" groupName="小说">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="285882" type="recommend" title="北京河北地区小说列表" groupName="小说">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195877" type="recommend" title="北京河北地区小说新闻盒" groupName="小说">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="260335" type="struct" title="其它地区小说标题" groupName="小说">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="285883" type="recommend" title="其他地区小说列表" groupName="小说">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="225819" type="recommend" title="其他地区小说新闻盒" groupName="小说">
                        <div className={styles.chip_item} />
                    </Chip>
                </div>
                <div className={styles.top_40}>
                    <Chip id="200149" type="struct" title="北京河北地区动漫标题" groupName="动漫">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="285884" type="recommend" title="北京河北地区动漫列表" groupName="动漫">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="260334" type="struct" title="其它地区动漫标题" groupName="动漫">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="286051" type="recommend" title="其他地区动漫列表" groupName="动漫">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="260450" type="struct" title="皇家尚品根据城市控制显隐" groupName="皇家尚品">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="260898" type="struct" title="第一轮替换电商广告时间设置" groupName="广告">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="260903" type="struct" title="第二轮替换电商广告时间设置" groupName="广告">
                        <div className={styles.chip_item} />
                    </Chip>
                </div>
            </div>
        );

        return ReactDOM.createPortal(chipView, document.body);
    }
}

export default errorBoundary(ChipView);
